import { SimplePaletteColorOptions, ThemeOptions, lighten } from "@mui/material";

import palette from "./palette";

const palettePrimary = palette.primary as SimplePaletteColorOptions;

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        "&.createCTAButton": {
          color: "#FFFFFF",
          backgroundColor: palettePrimary.light,
          "&:hover": {
            backgroundColor: palettePrimary.main,
            boxShadow: "none",
          },
        },
      },
      outlinedPrimary: {
        "&:not([disabled])": {
          border: `1px solid ${palettePrimary.light}`,
          color: palettePrimary.light,
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: lighten(palettePrimary.main, 0.95),
            boxShadow: "none",
          },
        },
      },
      containedPrimary: {
        "&:not([disabled])": {
          color: "#FFFFFF",
          backgroundColor: palettePrimary.light,
          boxShadow: "none",
          "&:hover": {
            backgroundColor: palettePrimary.main,
            boxShadow: "none",
          },
        },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: "h4",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        lineHeight: 1,
        height: "1em",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        "& > * .sidebarLogo": {
          maxWidth: "90%",
          height: "80%",
          objectFit: "contain",
        },
      },
    },
  },
};

export default components;
